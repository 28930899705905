<template>
    <div class="box_image">
        <ImagePreview
            :image="image"
            :zoom="zoom"
            :girar="girar"
        />

        <BarraSimple 
            :position="widthWindow < 991 ? 'horizontal' : 'vertical'" 
            v-model="zoom" 
            :min="-5" 
            :max="30" 
            minText="-" 
            maxText="+" 
            :coloreado="true" 
        />
        <BarraSimple 
            position="horizontal" 
            v-model="girar" 
            :min="-90" 
            :max="90" 
            minText="-90°" 
            maxText="90°" 
        />


        <div></div>
    </div>
</template>

<script>
import BarraSimple from './BarraSimple.vue';
import ImagePreview from './ImagePreview.vue';

export default {
    components: {
        BarraSimple,
        ImagePreview,
    },
    props:{
        image: String
    },
    computed: {
        widthWindow() {
            return window.innerWidth;
        }
    },
    data() {
        return{
            zoom: 1,
            girar: 0,
        }
    },


}
</script>

<style>
.box_image {
    /* width: 100%; */

    display: grid;
    grid-template-rows: 300px 20px;
    grid-template-columns: 300px 20px;
    gap: 8px;
    place-content: center;
}

@media (max-width: 991px) {
    .box_image {
        grid-template-rows: 300px;
        grid-template-columns: 300px;
    }
    
}


</style>